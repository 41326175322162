<template>
  <div class="vh76Container w-full" ref="basketContainer">
    <div
      v-if="basketLoading"
      class="text-center w-full py-84 h-full sm:py-48 md:py-64 lg:py-84"
    >
      <base-svg
        class="h-4 w-4 mr-1 text-primary-red inline-block"
        src="icons/circleSpinner.svg"
        tag="span"
      />
      Loading ...
    </div>
    <div v-else>
      <basket-tab
        :assets-count="basket ? basket.length : 0"
        class="mb-5"
      />
      <div class="flex justify-center mb-5">
        <base-button
          v-if="basket && basket.length"
          variant="btn-primary"
          class="mr-4"
          :disabled="proxyUser"
          text="Clear"
          @click="confirmDeleteBasketItem()"
        />
        <base-button
          v-if="basket && basket.length"
          variant="btn-primary"
          class="mr-4"
          text="Download"
          @click="setDownloadBasketModalVisibility(true)"
        />
        <base-button
          v-if="!isSearchParamEmpty"
          variant="btn-primary"
          class="mr-4"
          text="Back"
          @click="handleBack()"
        />
      </div>
      <div class="flex flex-wrap">
        <div
          v-for="(basketItem, index) in basket"
          :key="'basket-asset-card' + index"
          class="w-1/2 pr-8 pb-6 xs:w-1/4 lg:w-1/5 2xl:w-1/6 max-w-full h-auto cursor-pointer rounded-sm"
        >
          <basket-asset-card
            :basket-item="basketItem"
            @click="openAssetDetails(basketItem)"
          />
        </div>
      </div>
      <div
        v-if="basket && basket.length"
        class="text-right mb-2 mr-4"
      >
        <base-button
          variant="btn-primary"
          text="Go to top"
          @click="scrollToTop($event)"
        />
      </div>
      <!-- download basket modal -->
      <download-basket-modal
        v-model="showDownloadBasketModal"
        @hide="setDownloadBasketModalVisibility(false)"
      />
      <!-- assets details modal -->
      <style-guide-asset-details-modal
        v-model="showAssetsDetailsModal"
        :asset-details="selectedAssetDetails"
      />
    </div>
    <div v-if="basket && basket.length === 0" class="text-center">
      Your basket is empty
    </div>
  </div>
  <base-modal
    v-model="showDeleteBasketModal"
    modal-title="Confirm Delete Basket Item"
    @hidden="resetDeleteBasketModal()"
  >
    <template #modalBody>
      <div class="px-4 py-3">
        <div class="font-sm text-center mb-2">
          Are you sure to delete all items from basket ?
        </div>
        <div class="text-center mt-2">
          <base-button
            class="mr-3"
            variant="btn-primary"
            text="Yes"
            :is-loading="basketLoading"
            @click="clearAllBasket()"
          />
          <base-button
            variant="btn-link"
            text="No"
            @click="resetDeleteBasketModal()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';

import useDownloadManager from './downloadManager';

export default {
    name: 'BasketSection',

    components: {
        BasketAssetCard: defineAsyncComponent(() => import('./BasketAssetCard.vue')),
        BasketTab: defineAsyncComponent(() => import('./BasketTab.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        DownloadBasketModal: defineAsyncComponent(() => import('./DownloadBasketModal.vue')),
        StyleGuideAssetDetailsModal: defineAsyncComponent(() => import('@/components/StyleGuideAssetDetailsModal.vue')),
        BaseSvg,
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue'))
    },

    setup () {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const proxyUser = computed(() => store.getters['auth/getUserProxyInfo']);

        // download basket modal logic
        const showDownloadBasketModal = ref(false);
        const setDownloadBasketModalVisibility = (visibility) => {
            // if (visibility) resetFileTracker(true)
            showDownloadBasketModal.value = visibility;
        };

        // asset details logic
        const showAssetsDetailsModal = ref(false);
        const selectedAssetDetails = ref({});
        const openAssetDetails = (asset) => {
            selectedAssetDetails.value = asset;
            showAssetsDetailsModal.value = true;
        };

        // fetching basket logic
        const basketLoading = ref(true);
        const basket = computed(() => store.getters['basket/getBasketList']);
        const fetchBasket = async () => {
            try {
                basketLoading.value = true;
                await store.dispatch('basket/fetchBasketList');
            } catch (err) {
                console.error(err);
            } finally {
                basketLoading.value = false;
            }
        };
        fetchBasket();

        // clear basket logic
        const clearAllBasket = async () => {
            try {
                basketLoading.value = true;
                await store.dispatch('basket/clearAllBasket');
            } catch (err) {
                console.error(err);
            } finally {
                await fetchBasket();
                basketLoading.value = false;
                resetDeleteBasketModal();
            }
        };

        const clearBasket = async () => {
            try {
                basketLoading.value = true;
                await store.dispatch('basket/clearBasket');
            } catch (err) {
                console.error(err);
            } finally {
                basketLoading.value = false;
            }
        };

        // confirm delete search modal logic
        const confirmDeleteBasketItem = () => {
            // basketToBeDeleted.value = basket;
            setDeleteBasketModalVisibility(true);
        };

        const showDeleteBasketModal = ref(false);
        const setDeleteBasketModalVisibility = (visibility) => {
            showDeleteBasketModal.value = visibility;
        };
        // const basketToBeDeleted = ref(null);
        const resetDeleteBasketModal = () => {
            // basketToBeDeleted.value = null;
            setDeleteBasketModalVisibility(false);
        };

        const { searchAssets, searchParams } = useStyleGuideAssets();
        const isSearchParamEmpty = computed(() => Object.keys(searchParams.value).length < 1 ? true : false)

        // back navigation logic
        const handleBack = async () => {
          store.dispatch('assets/setSearchPage', 1 )
          await searchAssets();
          if (route.name!='StyleGuide') router.push({ name: 'StyleGuide' });
        };

        const scrollToTop = (tmpEvent) => {
            tmpEvent.currentTarget.parentElement.parentElement.parentElement.scrollTo(0, 0);
        };

        onMounted(async () => {
          // NOTE: Need this fetch call so that the DAM session is established
          await store.dispatch('activeDownloads/fetchActiveDowloadList');
        })

        const { resetFileTracker } = useDownloadManager();


        return {
            // currentPage,
            // perPageLimit,
            // perPageLimitOptions,
            // handlePage,
            showDownloadBasketModal,
            setDownloadBasketModalVisibility,
            showAssetsDetailsModal,
            openAssetDetails,
            selectedAssetDetails,
            basket,
            basketLoading,
            fetchBasket,
            clearBasket,
            clearAllBasket,
            handleBack,
            scrollToTop,
            confirmDeleteBasketItem,
            resetDeleteBasketModal,
            showDeleteBasketModal,
            isSearchParamEmpty,
            proxyUser
        };
    }
};
</script>

<style scoped>

.vh76Container{
  max-height: 76vh;
  min-height: 76vh;
  overflow: scroll;
}

</style>
